<template>
  <div class="feedback-container">
    <div class="feeedbackImage">
      <img src="../assets/contact/total-AI.png"/>
    </div>
    <div class="feedbackCon-header">
      <div class="feedback-title">
        关于我们
      </div>
      <div class="img-container">
        <img src="../assets/contact/bgmCat.png"/>
        <img src="../assets/contact/deepmusic.png"/>
      </div>
      <div class="characters-container">
        <div>
          <span>BGM猫</span>是由<span>DeepMusic</span><span>AI音乐团队</span>研发的用来<span>生成BGM</span>的工具，我们利用AI音乐生成技术使音乐的风格、情绪、时长、段落、能量等变得可控，旨在提供可商用和个人使用的优质音乐内容，降低音乐获取难度及成本。
        </div>
        <div>
          目前产品还处于初期阶段，我们也在努力迭代产品，
          使得音乐生成更加智能精确，内容更加丰富多样。
          欢迎大家提出宝贵的意见和建议。
        </div>
      </div>
    </div>
    <div class="feedbackCon-footer">
      <div class="feedback-content">
        <div class="content-tabs">
          <span @click="changeTabIndex(0)" :class="tabIndex == 0 ? 'selected' : ''">反馈</span>
          <span @click="changeTabIndex(1)" :class="tabIndex == 1 ? 'selected' : ''">联系我们</span>
          <span @click="changeTabIndex(2)" :class="tabIndex == 2 ? 'selected' : ''">使用帮助</span>
          <div class="tips-prompt" 
            :style="tabIndex == 0 ? 'margin-left: 4px;'
            :tabIndex == 1  ? 'margin-left: 96px;' : 'margin-left: 205px;'">
          </div>
        </div>
        <div v-if="tabIndex == 0" class="content-container0">
          <v-select class="selected-control" :items="scoreArr" label="请评分" solo  v-model="scoreIndex"></v-select>
            <div class="feedback">
              <span v-for="(item, index) in feedbackArr" :key="index" @click="chooseFeedBack(item)" :class="feedbackLabel == item ? 'choosedFeedBack' : ''">{{item}}</span>
            </div>
            <textarea placeholder="输入反馈内容" v-model="feedbackContent"></textarea>
            <br/>
            <v-btn class="ma-2 submitButton" :class="submitState ? '' : 'cantsubmit'" :loading="submiting" :disabled="submiting" color="secondary" @click="sendFeedback">
              提交
            </v-btn>
        </div>
        <div v-if="tabIndex == 1" class="content-container1">
          <div class="erCode">
            <div class="weChat">
              <img src="../assets/customerService.png"/>
              <div>微信</div>
            </div>
            <div class="tencent">
              <img src="../assets/contact/tencent.png"/>
              <div>QQ</div>
            </div>
          </div>
          <p class="contact"><img src="../assets/contact/tel.png"/>chenxy@lazycomposer.com</p>
        </div>
        <div v-if="tabIndex == 2" class="content-container2">
          <div class="FAQ-container" v-for="(item, index) in FAQArr" :key="index">
            <div class="question-container">
              <img :src="item.questionImg"/>
              {{item.question}}
            </div>
            <div class="answer-container">
              {{item.answer}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {axiosPost} from "../../src/axios.js"
import {ADD} from "../../src/api.js"
import qs from 'qs'
export default {
  data: () => ({
    timeout: 1500,

    tabIndex: 0, 
    submiter: null,
    submiting: false,
    submitState: false, // 反馈提交按钮状态
    scoreIndex: "请评分", // 评分下标
    scoreArr: ['无感', '还行', '🐂 🍺', '很🐂 🍺'], // 评分数组
    feedbackContent: '', // 反馈内容
    feedbackLabel: '', // 反馈下标
    feedbackArr: ['功能建议', '曲风建议', 'BUG反馈', '其他'], // 反馈分类数组
    FAQArr: [
      {
        "questionImg": require("../assets/contact/conatct_question.png"),
        "question": "如何得到一条我想要的BGM",
        "answer": "在主页您可以输入一段任意的描述或者选择几个标签，然后输入您需要的音乐时长，点击生成即可以得到一段对应的BGM；每一个生成的BGM您可以继续调整高潮点的位置来满足您更细致的需求。"
      },
      {
        "questionImg": require("../assets/contact/contaxt_attention.png"),
        "question": "我购买了会员，什么时候会扣除我每月的下载额度想要的BGM",
        "answer": "当您生成了一段满意的BGM点击成品下载中的WAV或MP3时会自动扣除您的会员月下载额度。"
      },
      {
        "questionImg": require("../assets/contact/contact_download.png"),
        "question": "重复下载同一条BGM会扣除下载额度吗？",
        "answer": "重复下载同一条BGM不会扣除下载额度。"
      },
      {
        "questionImg": require("../assets/contact/contact_reWrite.png"),
        "question": "我下载过的BGM还可以进行修改吗？",
        "answer": "当前您已经下载过的BGM是无法进行修改的，只能在个人中心的购买记录中播放试听；但是您可以在下载的同时收藏BGM，这样就可以继续在收藏列表中继续调整BGM的高潮点，当然每次调整后重新渲染的BGM会作为一条新的BGM下载；我们建议您如果对于高潮点需要反复调整的情况，可以先采用有水印的试听下载，待确定好高潮点再进行成品下载。"
      },
      {
        "questionImg": require("../assets/contact/contact_useScope.png"),
        "question": "我下载的BGM可以用在哪些地方使用？",
        "answer": "您下载的BGM可以在您激活授权后填写的授权书信息中的对应项目中随意使用。"
      },
      {
        "questionImg": require("../assets/contact/contact_useScope.png"),
        "question": "我下载的BGM可以商用吗，有版权吗？",
        "answer": "如果您想获得具有商用授权的BGM，可以通过购买专业创作者会员或单曲购买商业用途，下载BGM完成后在个人中心的购买记录中选择相应BGM激活音乐授权，填写授权信息，然后您便可以得到该BGM的商用授权书同时可以在您填写的商用项目场景中随意使用该BGM；对于已经下载的BGM，您可以在个人中心的购买记录中选择增加授权，来获得您想应用的商用项目场景的授权。"
      },
      {
        "questionImg": require("../assets/contact/contact_invoice.png"),
        "question": "如何开具发票",
        "answer": "当前如果您需要对某首购买的单曲或订购的会员开具发票，可以直接通过网站提供的二维码或电话联系我们，我们会有专门的客服人员配合您完成发票开具，届时会核对您的订单信息并需要您提供开票信息；后续网站提供专门的申请开具发票通道，方便您的使用。"
      },
      {
        "questionImg": require("../assets/contact/contact_invoice.png"),
        "question": "购买单曲或开通会员后可以退款吗？",
        "answer": "当前暂时不支持退款，如果您有任何购买付款上的疑问，可以随时联系我们的客服，我们会为您尽力解答帮助。"
      },
    ]
  }),
  watch: {
      scoreIndex: function(val, oldVal) {
        if(val != -1) {
          this.submitState = true
        }
      },
      feedbackContent: function (val, oldVal) {
        if (this.feedbackLabel !== '' && val.length > 0) {
          this.submitState = true
        } else if (val.length === 0 && this.scoreIndex === -1) {
          this.submitState = false
        }
      }
    },
    mounted() {
      this.autoTextarea(document.getElementsByTagName("textarea")[0])
    },
  methods: {
    autoTextarea(elem, extra, maxHeight) {
        extra = extra || 0;
        var isFirefox = !!document.getBoxObjectFor || 'mozInnerScreenX' in window,
        isOpera = !!window.opera && !!window.opera.toString().indexOf('Opera'),
        addEvent = function (type, callback) {
                elem.addEventListener ?
                        elem.addEventListener(type, callback, false) :
                        elem.attachEvent('on' + type, callback);
        },
        getStyle = elem.currentStyle ? function (name) {
          var val = elem.currentStyle[name];

          if (name === 'height' && val.search(/px/i) !== 1) {
            var rect = elem.getBoundingClientRect();
            return rect.bottom - rect.top -
              parseFloat(getStyle('paddingTop')) -
              parseFloat(getStyle('paddingBottom')) + 'px';        
          };

          return val;
        } : function (name) {
          return getComputedStyle(elem, null)[name];
        },
        minHeight = parseFloat(getStyle('height'));
        elem.style.resize = 'none';
        var change = function () {
          var scrollTop, height,
              padding = 0,
              style = elem.style;
 
          if (elem._length === elem.value.length) return;
          elem._length = elem.value.length;

          if (!isFirefox && !isOpera) {
            padding = parseInt(getStyle('paddingTop')) + parseInt(getStyle('paddingBottom'));
          };
          scrollTop = document.body.scrollTop || document.documentElement.scrollTop;

          elem.style.height = minHeight + 'px';
          if (elem.scrollHeight > minHeight) {
            if (maxHeight && elem.scrollHeight > maxHeight) {
              height = maxHeight - padding;
              style.overflowY = 'auto';
            } else {
              height = elem.scrollHeight - padding;
              style.overflowY = 'hidden';
            };
            style.height = height + extra + 'px';
            scrollTop += parseInt(style.height) - elem.currHeight;
            document.body.scrollTop = scrollTop;
            document.documentElement.scrollTop = scrollTop;
            elem.currHeight = parseInt(style.height);
          };
        };
 
        addEvent('propertychange', change);
        addEvent('input', change);
        addEvent('focus', change);
        change();
    },
    // 提交反馈
    sendFeedback () {
      if (!this.submitState) {
        return
      }
      this.submiting = true
      this.submitState = false
      let index
      for(let i = 0; i < this.scoreArr.length; i++) {
        if(this.scoreArr[i] == this.scoreIndex) {
          index = i;
          break
        }
      }
      const data = {
        score: index,
        label: this.feedbackLabel,
        content: this.feedbackContent
      }
      axiosPost(ADD, qs.stringify(data)).then((res) => {
        this.submitState = true
        this.submiting = false
        this.$message.success("反馈成功");
      }).catch((error) => {
        this.submitState = true
        this.submiting = false
        this.$message.error("反馈失败,请再试一次");
      })
    },
    changeTabIndex(index) {
      this.tabIndex = index;
    },
    // 反馈模块
    chooseFeedBack (index) {
      this.feedbackLabel = index
      if (this.feedbackContent !== '') {
        this.submitState = true
      }
    },
  }
}
</script>


<style lang="scss" scoped>
.feedback-container{
  position: relative;
  margin-left: 88px !important;
  min-width: 1352px !important;
  width: calc(100vw - 88px);
  min-height: 900px !important;
  height: 100vh;
  overflow: scroll;
  z-index: 1;
  .feeedbackImage{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
    img{
      min-width: 704px;
      width: calc(704 / 1440 * 100vw);
    }
  }
  .feedbackCon-header{
    position: relative;
    z-index: 100;
    min-width: 1352px !important;
    width: calc(100vw - 88px);
    .feedback-title{
      padding: 80px 0 0 67px;
      font-family: PangMenZhengDao;
      font-style: normal;
      font-weight: normal;
      font-size: 40px;
      line-height: 134.5%;
      letter-spacing: 0.025em;
      color: rgba(0, 0, 0, 0.8);
    }
    .img-container{
      margin: 42px 0 0 62px;
      img:nth-child(1){
        width: 146px;
      }
      img:nth-child(2){
        margin-left: 42px;
        width: 205px;
      }
    }
    .characters-container{
      min-width: 637px;
      width: calc(637 / 1440 * 100vw);
      margin: 32px 0 0 68px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 146.5%;
      text-align: justify;
      letter-spacing: 0.025em;
      color: rgba(0, 0, 0, 0.8);
      span{
        font-weight: 500;
      }
      div:nth-child(2){
        margin-top: 12px;
      }
    }
  }
  .feedbackCon-footer{
    position: relative;
    z-index: 100;
  }
  .feedback-content{
    margin-top: 62px;
    width: 1219px;
    padding: 0px 64px;
    .content-tabs{
      border-bottom: 1px solid #e5e5e5;
      width: 344px;
      span{
        font-family: PingFang SC;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        align-items: center;
        letter-spacing: 0.025em;
        color: #666;
        cursor: pointer;
        margin-right: 34px;
      }
      .selected{
        color: #5490F4;
      }
      .tips-prompt{
        width:25px;
        height: 2px;
        border-radius: 2px;
        background: #000;
        background: #5490F4;
      }
    }
    .content-container0{
      .selected-control{
        margin-top: 30px;
      }
      .feedback{
        span{
          cursor: pointer;
          margin-right: 15px;
          display: inline-block;
          height: 28px;
          width: 74px;
          border-radius: 4px;
          background: #e5e5e5;
          font-family: PingFang SC;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 28px;
          text-align: center;
          color: #666666;
          border: 1px solid #F3F3F3;
        }
        .choosedFeedBack{
          background: #F6F6F6;
          color: #5490F4;
          font-weight: 500;
          border: 1px solid #5490F4;
        }
      }
      textarea{
        margin-top: 30px;
        width: 164px;
        max-height: 100px;
        outline: none;
        resize: none;
        border-top: none;
        border-left: none;
        border-right: none;
        border-radius: 0 !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
        font-family: PingFang SC;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #333;
        display: block;
        height: 20px;
      }
      textarea::-webkit-input-placeholder {
        color: #B8B8B8;
      }
      textarea:-moz-placeholder {
        color: #B8B8B8;
      }
      textarea::-moz-placeholder {
        color: #B8B8B8;
      }
      textarea::-ms-input-placeholder {
        color: #B8B8B8;
      }
      .submitButton{
        width: 120px;
        margin-top: 30px !important;
        height: 44px;
        border-radius: 24px;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 44px;
        text-align: center;
        color: #fff;
        background: #000 !important;
      }
      .cantsubmit{
        color: #999 !important;
        background: #E5E5E5 !important;
      }
    }
    .content-container1{
      .erCode{
        margin-top: 24px;
        display: flex;
        div{
          width: 96px;
          margin-right: 32px;
          img{
            width:  96px;
            height: 96px;
          }
          div{
            text-align: center;
            text-align: center;
            font-family: PingFang SC;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 17px;
            align-items: center;
            color: #666666;
            margin-top: 16px;
          }
        }
      }
      .contact{
        margin-top: 32px;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.005em;
        color: #666666;
        img{
          width: 20px;
          height: 20px;
          margin-right: 16px;
        }
      }
    }
    .content-container2{
      .FAQ-container{
        margin-top: 16px;
        padding: 6px 0px;
        .question-container{
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 25px;
          letter-spacing: 0.025em;
          color: rgba(0, 0, 0, 0.8);
          img{
            width: 25px;
            height: 25px;
            margin: -4px 5px 0 0;
          }
        }
        .answer-container{
          margin-top: 8px;
          margin-left: 37px;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: justify;
          letter-spacing: 0.065em;
          color: rgba(0, 0, 0, 0.7);
        }
      }
    }
  }
}
</style>

<style>
/* 反馈选项框 */
.selected-control .v-input__slot{
  border-radius: 0 !important;
  width: 142px !important;
  height: 18px !important;
  min-height: 30px !important;
  padding:  0px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3) !important;
  /* background: #f9f9f9 t; */
  background: #f3f3f3 !important;
  box-shadow: none !important;
}
.selected-control label{
  font-size: 12px !important;
}
.selected-control .v-select__selections {
  font-size: 12px !important;
}
.v-menu__content {
  box-shadow: none !important;
}
.v-menu__content .v-list{
  padding: 0 !important;
  text-align: center !important;
}

#list-35 .primary--text{
  color: #f3f3f3 !important;
}

.v-list .v-list-item--active{
  background:  #f3f3f3  !important;
}

.v-list-item--active .v-list-item__content{
  color: #000 !important;
}
</style>



<style>
/* 按钮阴影消失 */
.v-btn--is-elevated{
  box-shadow: none;
}
.v-btn--is-elevated{
  box-shadow: none;
}
.v-btn--is-elevated{
  box-shadow: none;
}
.v-btn--is-elevated{
  box-shadow: none;
  /* font-size: 16px !important; */
}
</style>